import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

import HotSellingProductsFeatured from 'images/featured-image/hot-selling-disinfection-product.png';
import HotSellingSportsImg from 'images/blog-images/hot-selling-sports.png';
import HotSellingDisinfectionImg from 'images/blog-images/hot-selling-disinfection-product.png';
import HotSellingTrendsImg from 'images/blog-images/hot-selling-trends.png';

interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query HotSellingProductsImg {
      file(relativePath: { eq: "hot-selling-disinfection-product.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="shipkoo hot selling disinfection products"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="Hot-Selling Products in the Post-COVID World | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-hot-selling" })}
         meta={
          [
            {
              name: `description`,
              content: "",
            },
            {
              property: `og:title`,
              // content: "Hot-Selling Products in the Post-COVID World",
              content: intl.formatMessage({ id: "blog-post-meta-title-hot-selling" }),
            },
            {
              property: `og:description`,
              content: "",
            },
            {
              property: `og:image`,
              content: HotSellingProductsFeatured,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="hot-selling-products-in-the-post-covid-world"
        title="Hot-Selling Products in the Post-COVID World"
        date="2020-07-27"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">
            <p>
              As the year-end promotions follow, which categories will different market consumers pay more attention to? What changes have taken place in hot-selling products and emerging markets compared to the same period last year? What will be the predicted hot-selling products for the post-COVID world? This post will provide tips based on different markets for the best product choices for your brand during this period of uncertainty.
            </p>

            <h5 className="no-bottom">
              <strong>
                European and American markets
              </strong>
            </h5>

            <p>
              Under the pandemic, it is obvious that the demand for certain categories’ products has increased rocketingly. These products include personal health care, such as electric massagers (back, legs, steps), electronic scales, fitness equipment; DIY beauty and makeup (such as nail tools, hair removal tools, hair dye sticks, facial masks, etc.).
            </p>

            <p>
              Amazon's products with high sales growth in the European and American markets meet the following common characteristics:
            </p>

            <OrderedList>
              <li>
                Daily consumables, with large purchase demand, high repurchase rate, and continuous rapid growth;
              </li>
              <li>
                Brand concentration is low, hot-selling products change continuously, and new products are relatively well accepted;
              </li>
              <li>
                Short product marketing period, the shortest can be completed in 3-6 months;
              </li>
              <li>
                The domestic supply chain is mature, with a wide range of options;
              </li>
              <li>
                The compliance market process is mature and the certification regulations are clear that you can find the corresponding service provider in the Amazon service provider list.
              </li>
            </OrderedList>

            <Image src={HotSellingSportsImg} className="" alt="shipkoo hot selling sports products"/>

            <h5 className="no-bottom">
              <strong>
                Latin American Market
              </strong>
            </h5>

            <p>
              Since the outbreak of the COVID19, the Latin American eCommerce market seems to usher in a new wave of development.
            </p>

            <p>
              According to relevant data, under the pandemic, the sales of Latin American home care products, laundry products, disinfectants, hand sanitizers and other products have increased significantly.
            </p>

            <p>
              Since the Latin American market does not prohibit the sale of disinfection products, platform sellers such as Wish and AliExpress have been selling such products.In addition to anti-epidemic supplies, because many offline physical stores in Latin America are still unable to resume business, local consumers are also transferring the products they are accustomed to buying offline to online. Therefore, household appliances, home furnishings, fashion, maternal and child products have also become hot-selling categories on several  business platforms.
            </p>

            <Image src={HotSellingDisinfectionImg} alt="shipkoo hot selling disinfection products"/>

            <h5 className="no-bottom">
              <strong>
                Southeast Asia Market
              </strong>
            </h5>

            <p>
              In a recent survey and analysis, Facebook and Bain report pointed out that Southeast Asian online retail is mainly focused in the fields of clothing, accessories and personal care. Under the influence of COVID19, nearly 50% of consumers in Southeast Asia spend more on buying fresh and packaged groceries online. In addition, 3C electronics, sports, beauty, cleaning, kitchen supplies and other categories have also seen significant growth.
            </p>

            <h5 className="no-bottom">
              <strong>
                Japanese and Korean market
              </strong>
            </h5>

            <p>
              During the post-COVID world in Japan, the demand for home sports equipment, pajamas, underwear, socks, indoor cleaning products, game products; and pet grooming have increased significantly. In addition, cooking, sewing, and storage products are also very popular.
            </p>

            <p>
              According to the "Daily Life Report" recently released by CJ Korea Express, household products have replaced travelling products and have become the main shopping needs of Koreans during the post-COVID world.
            </p>

            <p>
              Like other countries where the epidemic has occurred, the sales of remote office, home entertainment, and personal beauty care in the Korean eCommerce market have also seen a significant increase. According to eBay South Korea official news, South Korea's per capita online shopping consumption level has risen by 10%.
            </p>

            <Image src={HotSellingTrendsImg} alt="shipkoo hot selling trends"/>

            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="Facebook Icon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="Twitter Icon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="Linked Icon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;